//COLORS
$turquoise-600: #00C1CF;
$turquoise-500: #00DCDB;
$turquoise-400: #59E8E8;
$turquoise-300: #9BF1F1;
$turquoise-200: #D5F9F9;
$turquoise-100: #DCECEE;
$turquoise-50: #F1F7F8;

$green-600: #14758E;
$green-500: #0098BE;
$green-400: #56B4CB;
$green-300: #95CDDA;
$green-200: #D4E8ED;
$green-100: #D2E8E8;
$green-50: #ECF5F5;

$red-600: #AC054A;
$red-500: #F70069;
$red-400: #ED73A8;
$red-300: #F4ACCB;
$red-200: #FCE6EF;
$red-100: #EEDCE3;
$red-50: #F5F1F3;

$yellow-600: #EAAD28;
$yellow-500: #FFCA00;
$yellow-400: #FFE582;
$yellow-300: #FFF2BF;
$yellow-200: #FFFAE8;
$yellow-100: #EEE8D3;
$yellow-50: #F4F2E8;

$gray-600: #232323;
$gray-500: #585858;
$gray-400: #8A8C8D;
$gray-300: #D4D3D4;
$gray-200: #E7E7E7;
$gray-100: #F1F1F1;
$gray-50: #FBFBFB;



$white: #fff;
$black: #000;


// TIPOGRAPHY
$typography-ceraroundpro: 'CeraRoundPro', sans-serif;
$typography-letter-spacing: 0.01em;
$typography-color: $black;

// Player
$rhap_theme-color: $turquoise-600 !default;   // Color of all buttons and volume/progress indicators
$rhap_background-color: $turquoise-50 !default; // Color of the player background
$rhap_bar-color: #e4e4e4 !default;     // Color of volume and progress bar
$rhap_time-color: #333 !default;       // Font color of current time and duration
$rhap_font-family: inherit !default;   // Font family of current time and duration
