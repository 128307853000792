@import '../abstracts/variables';

.typography {
    font-family: inherit;

    svg {
        display:inline-block;
        vertical-align: middle;
    }

    > p:not(:last-child) {
        margin-bottom: 1rem;
    }

    &.one{
        font-size: 12px;
        line-height: 20px;
    }

    &.two{
        font-size: 14px;
        line-height: 22px;
    }

    &.three{
        font-size: 16px;
        line-height: 24px;
    }

    &.four{
        font-size: 20px;
        line-height: 28px;
    }

    &.five{
        font-size: 24px;
        line-height: 32px;
    }

    &.six{
        font-size: 30px;
        line-height: 38px;
    }

    &.seven{
        font-size: 38px;
        line-height: 46px;
    }

    &.eight{
        font-size: 46px;
        line-height: 54px;
    }

    &.regular{
        font-weight: 400;
    }

    &.medium{
        font-weight: 500;
    }

    &.bold{
        font-weight: 700;
    }

    &.black{
        font-weight: 900;
    }
}
