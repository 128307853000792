@import '../abstracts/variables';

.button {
    height: min-content;
    // padding: 14px 20px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $green-500;

    font-size: 1rem;
    line-height: 150%;

    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;

    border-radius: 0 30px 30px 30px;

    background-color: transparent;

    a {
        color: inherit;
        text-decoration: none;
    }

    svg {
        width: 1.25rem;
        height: 1.25rem;
        // display: none;
        vertical-align: middle;
    }

    &:hover {
        color: $green-400 !important;
    }
    &:active {
        color: $green-600 !important;
    }
    &.large {
        font-size: 1.25rem;
    }
    &.background {
        padding: 0 1.28125rem;
        height: 3rem;

        background-color: $green-500;
        color: $turquoise-200 !important;

        &:hover{
            background-color: $green-400;
            color: $turquoise-200 !important;
        }
        &:active {
            background-color: $green-600;
            color: $turquoise-200 !important;
        }
        &.disabled {
            opacity: 0.8;
            cursor: not-allowed;
        }
    }
    &.outline {
        height: 3rem;
        border-width: 1px;
        border-style: solid;
        // padding: 13px 19px;
        border-color: $green-500;
        padding: 0 1.28125rem;

        &:hover {
            border-color: $green-400;
        }
        &:active {
            border-color: $green-600;
        }

        &.pink {
            border-color: $red-500;
            color: $red-500;
            &:hover {
                border-color: $red-400;
                color: $red-400 !important;
            }
            &:active {
                border-color: $red-600;
                color: $red-600 !important;
            }
        }
    }
    &.arrow {
        & svg {
            display: flex;
        }
    }
    &.iconLeft {
        & svg {
            margin-right: .5rem;
        }
    }
    &.iconRight {
        flex-direction: row-reverse;

        & svg {
            margin-left: .5rem;
        }
    }
    &.rounded {
        width: 3rem;
        height: 3rem;
        border-radius: 50%;
        padding: 0;
        justify-content: center;
    }
    &.icon {
        color: black !important;
             display: flex;

        & svg:last-child {
            display: flex;
        }
    }
}
